<template>
  <div>
    <component :is="layout" v-bind="layoutProps">
      <router-view v-slot="{ Component, route }">
        <transition :name="route.meta && route.meta.transition">
          <component :is="Component" />
        </transition>
      </router-view>
      <!-- <keep-alive include=""> -->
      <!-- </keep-alive> -->
      <!-- <transition name="fade" appear> -->
      <!-- </transition> -->
    </component>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { Capacitor } from '@capacitor/core';
import { SplashScreen } from '@capacitor/splash-screen';
import { useFavicon } from '@vueuse/core';

import { IS_APP, IS_DEV, NEW_BUILD_CHECK_INTERVAL, DEFAULT_PAGE_TITLE } from '@/constants/index';
import { auth } from '@/lib/firebase';
import NotificationMixin from '@/mixins/NotificationMixin.js';
// NOTE (vue-router): fullPath query triggers reload even if we stay at the same component

// NOTE to silently update the URL:
// cf. https://stackoverflow.com/questions/51337255/silently-update-url-without-triggering-route-in-vue-router
// addHashToLocation(params) {
//   history.pushState(
//     {},
//     null,
//     this.$route.path + '#' + encodeURIComponent(params)
//   )
// }

export default {
  name: 'App',

  mixins: [NotificationMixin],

  data() {
    return {
      intervalId: null,
      currentVersion: null,
    }
  },

  created() {
    if (Capacitor.isNativePlatform()) {
      SplashScreen.hide();
    }
  },

  mounted() {
    this.intervalId = setInterval(this.checkVersion, NEW_BUILD_CHECK_INTERVAL)

    try {
      const url = new URL(window.location);
      const params = url.searchParams;
      const token = params.get('user_token');

      if (token) {
        if (url.pathname && url.pathname !== '/' && url.pathname !== '/login') {
          params.delete('user_token');

          this.$router.replace({
            path: url.pathname + '?' + params.toString(),
          });
        }

        this.$store.dispatch('user/loginWithUserToken', token);
      }
    } catch {
      // TODO
    }

    auth.onAuthStateChanged((user) => {
      if (!user) {
        this.$store.dispatch('app/reset');
        this.$store.dispatch('admin/reset');
        this.$store.dispatch('bank/reset');
        this.$store.dispatch('billing/reset');
        this.$store.dispatch('chat/reset');
        this.$store.dispatch('event/reset');
        this.$store.dispatch('finance/reset');
        this.$store.dispatch('products/reset');
        this.$store.dispatch('space/reset');
        this.$store.dispatch('settings/reset');
        this.$store.dispatch('receiver/reset');
        this.$store.dispatch('termsOfUse/reset');
        this.$store.dispatch('timeslot/reset');
        this.$store.dispatch('user/reset');
        this.$store.dispatch('validateUser/reset');
        this.$store.dispatch('saas/reset');
        this.$store.dispatch('comms/reset');
        this.$store.dispatch('subscription/reset');
        this.$store.dispatch('termsOfUse/loadCurrentTerm');
      }

      this.$store.dispatch('settings/loadHabitatPasses');
      this.$store.dispatch('settings/loadSpecializations');

      if (user) {
        this.$store.dispatch('termsOfUse/loadAllTerms');
      } else {
        this.$store.dispatch('space/unsubscribeAll');
        this.$store.dispatch('admin/unsubscribeAll');
        this.$store.dispatch('receiver/unsubscribeAll');
        this.$store.dispatch('user/unsubscribeAll');
        this.$store.dispatch('surveys/unsubscribeAll');
        this.$store.dispatch('saas/unsubscribeAll');
        this.$store.dispatch('saas/unsubscribeMessages');

        this.unregisterNotifications();
      }
    });

    this.addNotificationListeners();
    this.askNotificationPermissionIfNecessary();
  },

  destroyed() {
    clearInterval(this.intervalId);
  },

  computed: {
    ...mapGetters('app', {
      appConfig: 'GET_CONFIG',
    }),
    ...mapGetters('user', {
      userId: 'GET_USER_UID',
      readOnly: 'GET_READONLY_INFO',
    }),
    ...mapGetters('subscription', {
      saasSubscription: 'GET_SAAS_SUBSCRIPTION',
    }),

    layout () {
      return (this.$route.meta && this.$route.meta.layout ? this.$route.meta.layout : 'empty') + '-layout'
    },

    layoutProps () {
      return (this.$route.meta && this.$route.meta.layoutProps ? this.$route.meta.layoutProps : {})
    },

    saasId() {
      return this.appConfig?.saasId || this.readOnly?.ownerSaasId;
    },

    saasLogo() {
      return this.appConfig?.saasLogo;
    },

    saasName() {
      return this.appConfig?.saasName;
    },
  },

  methods: {
    async checkVersion() {
      if (IS_DEV || IS_APP) {
        return;
      }

      try {
        const version = await this.$store.dispatch('app/checkVersionBuild');

        if (this.currentVersion && this.currentVersion !== version.buildId) {
          const reload = window.confirm('Você está usando uma versão desatualizada do Sistema. Você deseja atualizar o sistema ?');

          if (reload) {
            window.location.reload();
          }
        }

        this.currentVersion = version.buildId;
      } catch (error) {
        console.error(error);
      }
    },

    subscribe() {
      this.$store.dispatch('subscription/subscriberAll', this.saasId);
      this.$store.dispatch('saas/subscriberAll', this.saasId);
    },
  },

  watch: {
    userId: {
      handler(newValue) {
        if (newValue) {
          this.subscribe();
        }
      },
      immediate: true,
    },

    saasId: {
      handler(newValue) {
        if (newValue && this.userId) {
          this.subscribe();
        }
      },
      immediate: true,
    },

    saasName(newValue) {
      document.title = newValue || DEFAULT_PAGE_TITLE;
    },

    saasLogo(newValue) {
      useFavicon(newValue || '/favicon.ico');
    },

    saasSubscription(newValue) {
      if (newValue?.status === 'active') {
        this.$store.dispatch('saas/subscribeMessages', {
          saasId: newValue.productId,
          onMessage: (message) => {
            this.showNotification('Nova mensagem', message.content, () => {
              if (this.$route.name !== 'onwer-saas-dashboard') {
                this.$router.push({ name: 'onwer-saas-dashboard' });
              }
            });
          },
        });
      } else {
        this.$store.dispatch('saas/unsubscribeMessages');
      }
    },

    readOnly(newValue, oldValue) {
      const isSaasTeam = ['owner', 'recepcionist'].includes(newValue?.saasRole);

      if (isSaasTeam) {
        if (newValue.saasId !== oldValue?.saasId) {
          this.$store.dispatch('saas/subscribeMessages', {
            saasId: this.readOnly.saasId,
            onMessage: (message) => {
              this.showNotification('Nova mensagem', message.content, () => {
                if (this.$route.name !== 'onwer-saas-dashboard') {
                  this.$router.push({ name: 'onwer-saas-dashboard' });
                }
              });
            },
          });
        }
      } else {
        this.$store.dispatch('saas/unsubscribeMessages');
      }

      if (newValue) {
        this.$store.dispatch('comms/subscribeMessages', {
          userId: this.userId,
        });
      }
    },
  },
}
</script>

<style lang="scss">
.toast-container {
  margin-top: env(safe-area-inset-top);
}
</style>
