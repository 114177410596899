<template>
  <v-app :class="{ blurred: this.GET_IS_BLURRED }">
    <v-app-bar color="white" app>
      <div class="flex align-center relative">
        <span v-if="!isApp">Menu</span>
        <v-app-bar-nav-icon id="sidebar-nav-button" @click.stop="drawer = !drawer" />
      </div>
      <!-- <v-toolbar-title> -->
      <!--   <img src="/icon.png" alt="logo da habitat" class="nav-logo" @click="goToHome"> -->
      <!-- </v-toolbar-title> -->
      <!-- <v-spacer></v-spacer> -->
      <!-- <v-btn icon> -->
      <!--   <v-icon>mdi-magnify</v-icon> -->
      <!-- </v-btn> -->
      <!-- <v-btn icon> -->
      <!--   <v-icon>mdi-filter</v-icon> -->
      <!-- </v-btn> -->
      <!-- <v-btn icon> -->
      <!--   <v-icon>mdi-dots-vertical</v-icon> -->
      <!-- </v-btn> -->
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" :class="{ 'drawer-loading': GET_IS_LOGGING_IN }" app>
      <div class="flex align-center align-self-center flex-column" v-if="GET_IS_LOGGING_IN">
        Carregando usuário
        <v-progress-circular indeterminate color="primary" class="mx-auto mt-4" />
      </div>

      <div v-else>
        <v-list v-if="!isApp">
          <v-list-item>
            <v-list-item-content>
              <v-img :src="logoUrl" alt="logo" class="max-h-40" contain />
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider />

        <sidebar-menu v-if="isLogged">
          <template v-slot:top-menus>
            <v-list-item link to="/profile">
              <v-list-item-avatar size="48">
                <!-- NOTE: user image here? -->
                <!-- <v-img src="https://cdn.vuetifyjs.com/images/john.png"></v-img> -->
                <v-avatar :color="profileImage ? '' : 'primary'" class="white--text" size="48">
                  <v-img :alt="username" :src="profileImage" v-if="profileImage">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="secondary"
                        />
                      </v-row>
                    </template>
                  </v-img>

                  <span class="text-xl" v-else-if="initials">{{ initials }}</span>

                  <img alt="Perfil" src="~@/assets/img/habitat.png" v-else />
                </v-avatar>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title class="flex items-center">
                  <span class="text-h6 truncate" v-if="username">{{ username }}</span>
                  <span class="text-md truncate" v-else-if="usermail">{{ usermail }}</span>
                  <span class="text-h6 truncate" v-else>Perfil</span>

                  <v-spacer />

                  <v-icon>mdi-menu</v-icon>
                </v-list-item-title>
              </v-list-item-content>
              <!-- <v-list-item-action> -->
              <!--   <v-icon>mdi-menu-down</v-icon> -->
              <!-- </v-list-item-action> -->
            </v-list-item>

            <div class="flex flex-col items-center" v-if="canShowSwitchProfile">
              <div class="flex align-center gap-2 justify-center" v-if="!isAdminOrSuperAdmin">
                <p :class="{ 'mb-0 owner': true, 'active deep-purple--text': !showDoctorDashboard }">Proprietário</p>
                <v-switch v-model="showDoctorDashboard" class="doctor-owner-switch" inset dense @change="dashboardChanged" :loading="GET_IS_UPDATING" />
                <p :class="{ 'mb-0 doctor': true, 'active deep-purple--text': showDoctorDashboard }">Profissional</p>
              </div>
              <v-btn class="-mt-2 font-semibold text-sm deep-purple--text transition" @click="() => updateDashboardChoice(true)" :disabled="!isDashboardUpdated" text small>Salvar</v-btn>
            </div>

            <v-list-item v-if="isAdminOrSuperAdmin">
              <v-chip class="mx-auto" :color="isSuperAdmin ? 'deep-orange lighten-1' : 'orange'" text-color="white" small>
                <v-icon left small>
                  mdi-shield-star
                </v-icon>
                <b>{{ isSuperAdmin ? 'Super Administrador' : 'Administrador' }}</b>
              </v-chip>
            </v-list-item>
          </template>
        </sidebar-menu>

        <v-divider />

        <sidebar-menu
          title="Super Administrador"
          :tabs="superAdminTabs"
          v-if="isSuperAdmin"
        />

        <v-divider />

        <sidebar-menu
          title="Administrador"
          :disabled="mustAcceptTerms"
          :tabs="adminTabs"
          v-if="isAdminOrSuperAdmin"
        />

        <template v-if="!isSaasTeam">
          <v-divider />

          <sidebar-menu
            title="Profissional"
            :tabs="doctorTabs"
            v-if="isDoctorDashboard && !isAdminOrSuperAdmin"
          >
            <template v-slot:top-menus>
              <v-list-item :class="{ 'doctor-validation': true, 'validated': isDoctorValidated }" to="/doctor-validate" active-class="selected" color="white" :disabled="mustAcceptTerms || !hasDocument" link>
                <v-list-item-content>
                  <div class="doctor-validation-content flex flex-column align-center gap-2 pa-2 rounded">
                    <v-icon v-if="isDoctorValidated">mdi-check-decagram</v-icon>
                    <v-icon v-else-if="pendingOwnDoctorValidation" color="red">mdi-alert-decagram-outline</v-icon>
                    <v-icon v-else>mdi-decagram-outline</v-icon>
                    <span>{{ isDoctorValidated ? 'Profissional Validado' : 'Validar Profissional' }}</span>
                  </div>
                </v-list-item-content>
              </v-list-item>

              <v-list-item to="/search" color="white" :disabled="mustAcceptTerms" link>
                <v-list-item-content>
                  <div class="flex flex-column align-center gap-2 pa-2 rounded bg-gray-100">
                    <v-icon>mdi-magnify</v-icon>
                    <span>Realizar reserva</span>
                  </div>
                </v-list-item-content>
              </v-list-item>
            </template>
          </sidebar-menu>

          <sidebar-menu
            title="Proprietário"
            :disabled="mustAcceptTerms"
            :tabs="ownerTabs"
            v-if="!isDoctorDashboard && !isAdminOrSuperAdmin && !canShowSaasItems && isOldUser"
          />
        </template>

        <v-divider />

        <sidebar-menu
          title="SaaS"
          :disabled="mustAcceptTerms"
          :tabs="saasTabs"
          v-if="!$isApp && !isDoctorDashboard && !isAdminOrSuperAdmin"
        >
          <template v-slot:top-menus>
            <v-list-item to="/owner/saas/subscription" :disabled="mustAcceptTerms" link v-if="canShowSaasSubscriptionItem">
              <v-list-item-icon>
                <v-icon>mdi-file-document</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>
                  <span>
                    {{ (isSaasOwner) ? 'Configuração' : 'Assinatura' }}
                  </span>

                  <v-chip
                    class="ml-2"
                    :color="prettySubscriptionStatusColor(saasSubscription && saasSubscription.status)"
                    small
                    dark
                    v-if="saasSubscription && prettySubscriptionStatus(saasSubscription.status) || isSaas"
                  >
                    {{prettySubscriptionStatus(saasSubscription && saasSubscription.status)}}
                  </v-chip>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </sidebar-menu>

        <v-divider />

        <sidebar-menu
          title="Comum"
          :disabled="mustAcceptTerms"
          :tabs="commonTabs"
          v-if="isLogged && !isAdminOrSuperAdmin"
        >
          <template v-slot:bottom-menus>
            <v-list-item link to="/helpdesk" v-if="!isAdminOrSuperAdmin" disabled>
              <v-list-item-icon>
                <v-icon>
                  mdi-face-agent
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  Suporte

                  <v-chip content="" color="primary" class="ml-2" small dark outlined>
                    Em breve
                  </v-chip>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </sidebar-menu>

        <v-divider />

        <sidebar-menu v-if="isLogged" dense>
          <template v-slot:bottom-menus>
            <v-list-item id="sign-out-menu-item" to="#" @click="signout">
              <v-list-item-icon>
                <v-icon>
                  mdi-exit-to-app
                </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>
                  Sair
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </sidebar-menu>
      </div>
    </v-navigation-drawer>

    <terms-of-use-dialog :show="canShowTerms" :on-cancel="() => isShowingTerms = false" :closeable="false"/>
    <survey-dialog :show="canShowSurvey" :on-cancel="() => isShowingSurvey = false" :closeable="false"/>

    <v-dialog v-model="isShowingDataRequestDialog" width="500">
      <v-card>
        <v-card-title>Nova solicitação de compartilhamento de dados</v-card-title>

        <div class="flex justify-end">
          <v-btn
            color="grey"
            @click="isShowingDataRequestDialog = false"
            text
          >
            Fechar
          </v-btn>

          <v-btn
            color="info"
            @click="goToSettings"
            text
          >
            Ver
          </v-btn>
        </div>
      </v-card>
    </v-dialog>

    <v-main>
      <v-container :class="layoutProps.fullHeight ? 'h-full' : undefined">
        <slot />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import { IS_APP, IS_DEV } from '@/constants';
import ViewportMixin from '@/mixins/ViewportMixin.js'
import SidebarMenu from '@/components/SidebarMenu';
import SurveyDialog from '@/components/SurveyDialog';
import TermsOfUseDialog from '@/components/TermsOfUseDialog';

const UNREAD_KEYS = ['helpdesk-admin', 'doctor', 'owner', 'helpdesk']

export default {
  name: 'SidebarLayout',

  mixins: [
    ViewportMixin,
  ],

  components: {
    SidebarMenu,
    SurveyDialog,
    TermsOfUseDialog,
  },

  data() {
    return {
      isApp: IS_APP,

      showDoctorDashboard: false,
      chatsUnsubscribe: null,
      adminChatsUnsubscribe: null,

      chatUnreadCountForSection: {},

      isShowingTerms: false,
      isShowingSurvey: false,
      isShowingDataRequestDialog: false,

      // NOTE: Using null as the starting value for its v-model will initialize the drawer as closed on mobile and as open on desktop
      drawer: null,

      surveyTimer: null,
    }
  },

  created() {
    this.$store.dispatch('admin/subscriberAll');
    this.$store.dispatch('space/subscriberAll', {
      saasId: this.isSaasTeam ? this.readOnly?.saasId : undefined,
      ownerId: this.GET_USER_UID,
    });
    this.$store.dispatch('receiver/subscriberAll');
    this.$store.dispatch('user/subscribeReadonly');
    this.$store.dispatch('surveys/subscribePending')
      .then(() => {
        this.observeSurveys();
      });
    this.$store.dispatch('user/subscribePendingDataRequest');
  },

  mounted() {
    this.showDoctorDashboard = this.isApp ? true : this.preferences?.useDoctorDashboard;
    this.refreshUnreadCount();
  },

  computed: {
    ...mapGetters('app', {
      appConfig: 'GET_CONFIG',
    }),
    ...mapGetters('user', {
      isLogged: 'IS_CONNECTED_AND_VERIFIED',
      preferences: 'GET_PREFERENCES',
      userInfo: 'GET_USER_INFO',
      readOnly: 'GET_READONLY_INFO',
    }),
    ...mapGetters('user', [ 'GET_IS_LOGGING_IN', 'GET_USER_UID', 'GET_UNREAD_COUNTS_PER_KEY', 'GET_IS_UPDATING', 'GET_HAS_PENDING_DATA_REQUESTS' ]),
    ...mapGetters('validateUser', [ 'GET_OWN_DOCTOR_VALIDATION', 'GET_ALL_DOCTORS_VALIDATIONS_COUNT' ]),
    ...mapGetters('admin', {
        pendingSpacesCount: 'GET_ALL_PENDING_SPACES_COUNT',
        pendingHabitatPass: 'GET_ALL_PENDING_HABITAT_PASS_COUNT',
        pendingIntegral: 'GET_ALL_PENDING_INTEGRAL_COUNT',
        adminUnsubscribers: 'GET_UNSUBSCRIBERS',
      }),
    ...mapGetters('app', [ 'GET_IS_BLURRED' ]),
    ...mapGetters('subscription', {
      saasSubscription: 'GET_SAAS_SUBSCRIPTION',
    }),
    ...mapGetters('saas', {
      pendingDataRequest: 'GET_DATA_SHARE_REQUESTS',
      pendingAccessRequests: 'GET_PENDING_ACCESS_REQUESTS',
      pendingServicesRequests: 'GET_PENDING_SERVICES_REQUESTS',
    }),
    ...mapGetters('termsOfUse', { termsAccepted: 'ACCEPTED' }),

    layoutProps () {
      return (this.$route.meta?.layoutProps ? this.$route.meta.layoutProps : {})
    },

    tabs() {
      const hasValidations = this.GET_ALL_DOCTORS_VALIDATIONS_COUNT > 0;
      const hasPendingAds = this.pendingSpacesCount > 0;
      const hasPendingHabitatPass = this.pendingHabitatPass > 0;
      const hasPendingIntegral = this.pendingIntegral > 0;
      const hasPendingDataRequests = this.isShowingDataRequestDialog ? 1 : 0;
      const hasPendingAccessRequests = this.pendingAccessRequests > 0;
      const hasPendingServicesRequests = this.pendingServicesRequests > 0;
      const isDoctor = this.showDoctorDashboard;

      return [
        { title: this.$t('menu.adminDashBoard'), icon: 'mdi-desktop-mac-dashboard', to: '/admin-dashboard', role: 'admin', ready: true },
        // { title: 'Fornecer suporte', icon: 'mdi-face-agent', to: '/admin-helpdesk', role: 'admin', notificationKey: 'helpdesk-admin', ready: true },
        { title: this.$t('menu.subscriptions'), icon: 'mdi-file-document-outline', to: '/admin-subscriptions', role: 'admin', ready: true },
        { title: this.$t('menu.spaces'), icon: 'mdi-domain', to: '/admin-spaces', role: 'admin', ready: true },
        { title: this.$t('menu.coupons'), icon: 'mdi-ticket-percent', to: '/admin/coupons', role: 'admin', ready: true },
        { title: this.$t('menu.habitatPass'), icon: 'mdi-fire', to: '/admin-habitat-pass', role: 'admin', ready: true, alert: hasPendingHabitatPass },
        { title: this.$t('menu.integralRequests'), icon: 'mdi-post', to: '/admin-monthly-integral', role: 'admin', ready: true, alert: hasPendingIntegral },
        { title: this.$t('menu.transactions'), icon: 'mdi-cash', to: '/admin-transactions', role: 'admin', ready: true },
        { title: this.$t('menu.saas'), icon: 'mdi-account-multiple', to: '/admin/saas', role: 'admin', ready: true },
        { title: this.$t('menu.users'), icon: 'mdi-account-multiple', to: '/admin-users', role: 'admin', ready: true },
        { title: this.$t('menu.validateUsers'), icon: 'mdi-account-multiple', to: '/admin-validate-doctors', role: 'admin', ready: true, alert: hasValidations  },
        { title: this.$t('menu.validateAdvertisements'), icon: 'mdi-newspaper-variant', to: '/admin-validate-spaces', role: 'admin', ready: true, alert: hasPendingAds },
        { title: this.$t('menu.finance'), icon: 'mdi-finance', to: '/admin-finance', role: 'admin', ready: true },
        { title: this.$t('menu.reports'), icon: 'mdi-chart-pie', to: '/admin-reports', role: 'admin', ready: true },
        { title: this.$t('menu.blog'), icon: 'mdi-rss', href: 'https://blog.habitatconsultorios.com.br/', target: '_blank', role: 'admin', ready: true },

        { title: this.$t('menu.admin'), icon: 'mdi-shield-star', to: '/super-admin-invites', role: 'super-admin', ready: true },
        { title: this.$t('menu.configs'), icon: 'mdi-cog', to: '/admin-settings', role: 'super-admin', ready: true },

        // { title: 'Realizar reserva', icon: 'mdi-magnify', to: '/search', role: 'doctor', ready: true },
        { title: this.$t('menu.doctorDashboard'), icon: 'mdi-view-dashboard', to: '/doctor/dashboard', role: 'doctor', ready: true },
        {
          title: this.$t('menu.doctorCalendar'),
          icon: 'mdi-calendar-month',
          to: '/doctor-calendar',
          role: 'doctor',
          ready: true,
        },
        { title: this.$t('menu.doctorEvents'), icon: 'mdi-calendar-week', to: '/doctor-events', role: 'doctor', ready: true },
        { title: this.$t('menu.services'), icon: 'mdi-room-service', to: '/doctor-services', role: 'doctor', ready: true },
        // { title: 'Chat com proprietários', icon: 'mdi-message-text', to: '/chat-with-owners', role: 'doctor', notificationKey: 'doctor', ready: true, disabled: !this.isDoctorSignUpCompleted },
        { title: this.$t('menu.myProducts'), icon: 'mdi-basket', to: '/doctor-products', role: 'doctor', ready: true },
        { title: this.$t('menu.marketplace'), icon: 'mdi-shopping', to: '/doctor/marketplace', role: 'doctor', ready: true, new: true },

        { title: this.$t('menu.ownerDashboard'), icon: 'mdi-view-dashboard-variant', to: '/owner-dashboard', role: 'owner', ready: true },
        // { title: 'Chat com habitantes', icon: 'mdi-message', to: '/chat-with-doctors', role: 'owner', notificationKey: 'owner', ready: true },
        { title: this.$t('menu.calendar'), icon: 'mdi-calendar-month', to: '/owner-calendar', role: 'owner', ready: true },
        { title: this.$t('menu.events'), icon: 'mdi-calendar-week', to: '/owner-spaces-events', role: 'owner', ready: true },
        { title: this.$t('menu.myProperties'), icon: 'mdi-domain', to: '/owner-unities', role: 'owner', ready: true },
        { title: this.$t('menu.opportunities'), icon: 'mdi-wallet-giftcard', to: '/owner-opportunities', role: 'owner', ready: false, soon: true },
        { title: this.$t('menu.marketplace'), icon: 'mdi-shopping', to: '/owner-marketplace', role: 'owner', ready: false, soon: true },

        ...(this.canShowSaasItems ? [
          {
            title: this.$t('menu.dashboard'),
            icon: 'mdi-view-dashboard-variant',
            to: '/owner/saas/dashboard',
            role: 'saas',
            ready: this.isSaasSubscriptionActive,
          },
          { 
            title: this.$t('menu.team'),
            icon: 'mdi-account-tie',
            to: '/owner/saas/team',
            role: 'saas',
            ready: this.isSaasSubscriptionActive,
          },
          {
            title: this.$t('menu.saasUsers'),
            icon: 'mdi-account-multiple',
            to: '/owner/saas/users',
            role: 'saas',
            ready: this.isSaasSubscriptionActive,
            alert: hasPendingAccessRequests,
            badge: () => {
              return this.pendingAccessRequests;
            }
          },
          {
            title: this.$t('menu.properties'),
            icon: 'mdi-domain',
            to: '/owner/saas/unities',
            role: 'saas',
            ready: true
          },
          {
            title: this.$t('menu.calendar'),
            icon: 'mdi-calendar-month',
            to: '/owner/saas/calendar',
            role: 'saas',
            ready: true,
          },
          {
            title: this.$t('menu.events'),
            icon: 'mdi-calendar-week',
            to: '/owner/saas/events',
            role: 'saas',
            ready: true,
          },
          {
            title: this.$t('menu.services'),
            icon: 'mdi-room-service',
            to: '/owner/saas/services',
            role: 'saas',
            ready: this.isSaasSubscriptionActive,
            alert: hasPendingServicesRequests,
            badge: () => {
              return this.pendingServicesRequests;
            }
          },
        ] : []),

        ...((isDoctor || this.isOldUser || this.canShowSaasItems) ? [
          { title: this.$t('menu.finance'), icon: 'mdi-finance', to: isDoctor && !this.isSaasTeam ? '/doctor-finance' : '/owner-finance', role: 'common', ready: true }
        ] : []),
        ...(this.isSaas ? [] : [
          { title: this.$t('menu.blog'), icon: 'mdi-rss', href: 'https://blog.habitatconsultorios.com.br/', target: '_blank', role: 'common', ready: true },
        ]),
        { title: this.$t('menu.configs'), icon: 'mdi-cog', to: '/settings', role: 'common', ready: true, badge: () => {
          if (this.isDoctorDashboard) {
            return this.pendingDoctorConfigs;
          }

          return 0;
        }, alert: hasPendingDataRequests },
      ].filter((t) => !t.hidden);
    },

    isSuperAdmin() {
      return this.readOnly?.isSuperAdmin;
    },

    isAdminOrSuperAdmin() {
      return this.readOnly?.isAdmin || this.readOnly?.isSuperAdmin;
    },

    canShowSaasItems() {
      return this.hasSaasSubscription
          && this.readOnly?.ownerSaasId
          || this.readOnly?.saasRole
          && this.readOnly?.saasRole !== 'user';
    },

    canShowSaasSubscriptionItem() {
      return !this.isSaas || this.readOnly?.ownerSaasId?.trim() || this.readOnly?.saasRole === 'owner';
    },

    canShowSwitchProfile() {
      return !this.isApp && !this.isSaas;
    },

    isDoctorValidated() {
      return this.readOnly?.isDoctorValidated;
    },

    isDoctorDashboard() {
      if (this.isApp) {
        return true;
      }

      if (this.isSaas) {
        return !this.isSaasTeam;
      }

      return this.canShowSwitchProfile && this.showDoctorDashboard;
    },

    isDashboardUpdated() {
      if (!this.preferences) {
        return false;
      }

      return this.userInfo?.preferences?.useDoctorDashboard !== this.showDoctorDashboard;
    },

    pendingOwnDoctorValidation() {
      return this.GET_OWN_DOCTOR_VALIDATION && !this.GET_OWN_DOCTOR_VALIDATION.isRead;
    },

    doctorValidationClass() {
      return this.isDoctorValidated ?
        'deep-purple lighten-5 deep-purple--text text--accent-4' :
        'grey lighten-3  grey--text';
    },

    impersonatingAdmin() {
      return this.isHelpDesk && this.selfAsHabitat && this.isAdminOrSuperAdmin
    },

    myId() {
      // TODO: enable secretary impersonation
      return this.impersonatingAdmin ? '__habitat_help' : this.GET_USER_UID;
    },

    username() {
      return this.userInfo?.name ? this.userInfo.name.split(' ').slice(0, 2).join(' ') : '';
    },

    profileImage() {
      return this.userInfo?.profileImageUrl ?? null;
    },

    usermail() {
      return this.userInfo?.email ?? '';
    },

    initials() {
      if (!this.userInfo?.name) {
        return null;
      }
      return this.userInfo.name.split(' ').slice(0, 2).map(s => s.charAt(0)).join('');
    },

    hasDocument() {
      return this.userInfo?.socialSecurity || this.userInfo?.cnpj;
    },

    mustAcceptTerms() {
      return this.termsAccepted === false;
    },

    routeTitle() {
      return this.$router.currentRoute?.meta?.title;
    },

    routeBack() {
      return this.$router.currentRoute?.meta?.backTo;
    },

    canShowTerms() {
      return this.isLogged
          && this.isShowingTerms
          && !this.readOnly?.mustSetPassword;
    },

    canShowSurvey() {
      return this.isLogged
          && this.isShowingSurvey
          && !this.isShowingTerms
          && !this.readOnly?.mustSetPassword;
    },

    doctorTabs() {
      return this.tabs.filter(t => t.role === 'doctor');
    },

    ownerTabs() {
      return this.tabs.filter(t => t.role === 'owner');
    },

    commonTabs() {
      return this.tabs.filter(t => t.role === 'common');
    },

    saasTabs() {
      return this.tabs.filter(t => t.role === 'saas');
    },

    adminTabs() {
      return this.tabs.filter(t => t.role === 'admin');
    },

    superAdminTabs() {
      return this.tabs.filter(t => t.role === 'super-admin');
    },

    logoUrl() {
      if (this.isSaas) {
        return this.appConfig?.saasLogo;
      }

      return '/icon.png';
    },

    isOldUser() {
      return this.readOnly?.isOldUser;
    },

    saasId() {
      return this.appConfig?.saasId || this.readOnly?.ownerSaasId;
    },

    isSaas() {
      return !!this.readOnly?.saasId;
    },

    isSaasTeam() {
      return this.isSaas && this.readOnly?.saasRole !== 'user';
    },

    isSaasOwner() {
      return this.readOnly?.ownerSaasId || this.isSaas && this.readOnly?.saasRole !== 'owner';
    },

    hasSaasSubscription() {
      return !!this.saasSubscription;
    },

    isSaasSubscriptionActive() {
      return this.saasSubscription && this.saasSubscription.status !== 'inactive';
    },

    pendingDoctorConfigs() {
      let total = 0;

      if (this.pendingDataRequest) {
        total += this.pendingDataRequest.length;
      }

      return total;
    },

    hasDataRequest() {
      return this.$router.currentRoute?.name !== 'settings-data-share-list' && this.GET_HAS_PENDING_DATA_REQUESTS;
    },
  },

  methods: {
    ...mapActions('user', ['signout']),

    refreshUnreadCount() {
      for (const k of UNREAD_KEYS) {
        this.$set(this.chatUnreadCountForSection, k, 0)
        if (k in this.GET_UNREAD_COUNTS_PER_KEY) {
          this.$set(this.chatUnreadCountForSection, k, Object.values(this.GET_UNREAD_COUNTS_PER_KEY[k]).reduce((a, b) => a + b, 0))
        }
      }
    },

    goToHome() {
      if (this.$router.currentRoute && this.$router.currentRoute.name !== 'home') {
        this.$router.push({ name: 'home' })
      } else {
        this.$router.go()
      }
    },

    async goToSettings() {
      if (this.$router.currentRoute?.name === 'settings-data-share-list') {
        return;
      }

      this.showDoctorDashboard = true;
      this.$router.push({ name: 'settings-data-share-list' });
      this.isShowingDataRequestDialog = false;
    },

    async dashboardChanged() {
      let ownerDashboard = 'owner-dashboard';

      if (this.isSaasTeam || this.isSaasSubscriptionActive) {
        ownerDashboard = 'owner-saas-dashboard';
      }

      this.$router.push({ name: this.showDoctorDashboard ? 'doctor-dashboard' : ownerDashboard });
    },

    async updateDashboardChoice(save) {
      await this.$store.dispatch('user/updatePreferences', {
        payload: {
          useDoctorDashboard: this.showDoctorDashboard,
        },
        save,
      });
    },

    observeSurveys() {
      if (this.surveyTimer) {
        clearInterval(this.surveyTimer);
      }

      const checkSurvey = async () => {
        if (this.isShowingSurvey) {
          return;
        }

        const survey = await this.$store.dispatch('surveys/checkSurveys');
        this.isShowingSurvey = !!survey;
      };

      this.surveyTimer = setInterval(checkSurvey, 1_000 * (IS_DEV ? 15 : 30));
      checkSurvey();
    },

    prettySubscriptionStatus(status) {
      switch(status) {
        case 'active':
          return 'Ativa';
        case 'overdue':
          return 'Atrasada';
        case 'inactive':
          return 'Inativa';
        default: return null;
      }
    },

    prettySubscriptionStatusColor(status) {
      switch(status) {
        case 'active':
          return 'success';
        case 'overdue':
          return 'error';
        case 'inactive':
          return 'gray';
        default: return null;
      }
    },
  },

  watch: {
    GET_UNREAD_COUNTS_PER_KEY: {
      handler() {
        this.refreshUnreadCount()
      },
      deep: true,
    },

    showDoctorDashboard: {
      handler() {
        this.updateDashboardChoice(false);
      },
    },

    termsAccepted: {
      handler() {
        this.isShowingTerms = this.termsAccepted === false;
      },
      immediate: true,
    },

    hasDataRequest(value) {
      this.isShowingDataRequestDialog = value;
    }
  },

  beforeDestroy() {
    if (this.chatsUnsubscribe) {
      this.chatsUnsubscribe()
    }
    if (this.adminChatsUnsubscribe) {
      this.adminChatsUnsubscribe()
    }

    if (this.surveyTimer) {
      clearInterval(this.surveyTimer);
    }

    this.$store.dispatch('admin/unsubscribeAll');
  },
}
</script>

<style lang="scss" scoped>
.v-toolbar__title {
  overflow: visible;
  display: flex;
}

.doctor-owner-switch {
  cursor: pointer;
  margin-right: -12px;
  margin-left: 4px;
}

::v-deep .doctor-owner-switch .v-input--switch__track {
  background-color: #7e57c2 !important;
}

::v-deep .doctor-owner-switch .v-input--switch__thumb {
  background-color: white !important;
}

.doctor-owner-container {
  flex: 0;
  padding: 8px 12px;

  p {
    color: gray;
  }

  .doctor-owner-switch {
    ::v-deep .v-input__slot {
      margin: 0;
    }

    ::v-deep .v-messages {
      min-height: 0px;
    }
  }

  .owner {
    margin-right: 0.1rem;
  }

  .doctor {
    margin-left: -1rem;
  }

  .active {
    font-weight: bold;
  }
}

.blurred {
  filter: blur(3px);
  pointer-events: none;
}

.doctor-validation {
  .doctor-validation-content {
    background-color: #eee !important;
  }

  &.selected .doctor-validation-content {
    background-color: #6200ea1e !important;
    color: #6200ea !important;
  }
}

.doctor-validation.validated:not(.selected) {
  .doctor-validation-content {
    color: #598000 !important;
    background-color: #91b50945 !important;
  }

  .v-icon {
    color: #598000 !important;
  }
}

::v-deep .drawer-loading .v-navigation-drawer__content {
  display: flex;
}

.last-menu {
  padding-bottom: 96px;
}
</style>

<style lang="scss" scoped>
.v-application--wrap {
  margin-top: env(safe-area-inset-top);
}

.v-app-bar {
  margin-top: env(safe-area-inset-top) !important;
}
.v-navigation-drawer__content {
  padding-top: env(safe-area-inset-top);

  [role="separator"] + [role="separator"] {
    visibility: hidden;
  }
}
</style>
